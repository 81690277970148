import { Component } from '@angular/core';
// import { AngularFireDatabase, AngularFireList } from '@angular/fire/database';
// import { AngularFireDatabase, FirebaseListObservable } from '@angular/fire/database-deprecated';
import { AngularFirestore } from '@angular/fire/firestore';
import { League } from 'src/models/LeagueModel';
import { Observable } from 'rxjs';
import * as firebase from 'firebase';
import firestore from 'firebase/firestore';
import * as GoogleFirebaseAuth from './../../config.js';

export interface Item { name: string; }
const settings = {timestampsInSnapshots: true};
const config = GoogleFirebaseAuth.FIREBASE_API;
@Component({
  selector: 'app-root',
  templateUrl: './app.component.html',
  styleUrls: ['./app.component.scss']
})
export class AppComponent {
  // title = 'Fantasy';

  public items: Observable<any[]>;
  constructor(db: AngularFirestore) {
      this.items = db.collection('/leagues').valueChanges();
  }

  ngOnInit(){
    // firebase.initializeApp(config);
    // firebase.firestore().settings(settings);
  }
}
