import { Injectable } from '@angular/core';
import { Observable } from 'rxjs';
import { map } from 'rxjs/operators';
import * as firebase from 'firebase';
import firestore from 'firebase/firestore';

@Injectable({
  providedIn: 'root'
})



export class FsService {

  firebaseReference = firebase.firestore().collection('leagues');
  constructor() { }

  getBoards(): Observable<any> {
    return new Observable((observer) => {
      this.firebaseReference.onSnapshot((querySnapshot) => {
        // tslint:disable-next-line:prefer-const
        let boards = [];
        querySnapshot.forEach((doc) => {
          // tslint:disable-next-line:prefer-const
          let data = doc.data();
          boards.push({
            key: doc.id,
            title: data.name,
            description: data.description,
            author: data.author
          });
        });
        observer.next(boards);
      });
    });
  }

  getBoard(id: string): Observable<any>{
    return new Observable((observer) => {
      this.firebaseReference.doc(id).get().then((doc) => {
        let data = doc.data();
        observer.next({
          key: doc.id,
          title: data.title,
          description: data.description,
          author: data.author
        });
      });
    });
  }

  postBoards(data): Observable<any> {
    return new Observable((observer) => {
      this.firebaseReference.add(data).then((doc) => {
        observer.next({
          key: doc.id,
        });
      });
    });
  }

  updateBoards(id: string, data): Observable<any>{
    return new Observable((observer) => {
      this.firebaseReference.doc(id).set(data).then(() => {
        observer.next();
      });
    });
  }

  deleteBoards(id: string): Observable<{}> {
    return new Observable((observer) => {
      this.firebaseReference.doc(id).delete().then(() => {
        observer.next();
      });
    });
  }
}
