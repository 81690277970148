
export const YAHOO_API = {
    AppID : 'XDmEbk4e',
    ClientID : 'dj0yJmk9REo3ZGNJQVlHWFpiJmQ9WVdrOVdFUnRSV0pyTkdVbWNHbzlNQS0tJnM9Y29uc3VtZXJzZWNyZXQmeD1iZQ--',
    ClientSecret : '50ff4c22011de5eb8244d97b36a6299dc766f1bc'
}

export const FIREBASE_API = {
    apiKey: "AIzaSyAb9OuXd8QPin5usjVAh49j88-6nDi-6Xw",
    authDomain: "fantasy-aggregator-7723b.firebaseapp.com",
    databaseURL: "https://fantasy-aggregator-7723b.firebaseio.com",
    projectId: "fantasy-aggregator-7723b",
    storageBucket: "fantasy-aggregator-7723b.appspot.com",
    messagingSenderId: "161417100743"
};